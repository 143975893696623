// eslint-disable-next-line 
const navArray = [
    {
        title: '게임안내',
        href: '/',
        to: 'section_1'
    },
    {
        title: '게임소개',
        href: '/game',
        to: 'section_2'
    },
    {
        title: '서비스',
        href: '/',
        to: 'section_3'
    },
    {
        title: '문의',
        href: '/',
        to: 'section_4'
    }
];
export const navArrayMobile = [
    {
        title: '게임안내',
        href: '/',
        to: 'nav_1'
    },
    {
        title: '게임소개',
        href: '/game',
        to: 'section_2_mobile'
    },
    {
        title: '서비스',
        href: '/',
        to: 'section_3'
    },
    {
        title: '문의',
        href: '/',
        to: 'section_4'
    }
];

export default navArray