import * as React from "react"
const XIcon = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
        <path
            fill="#222"
            fillRule="evenodd"
            d="M20 0c11.046 0 19.1 8.954 19.1 19.1 0 11.946-8.054 20-19.1 20-11.046 0-20-8.054-20-20C0 8.954 8.954 0 20 0z"
        />
        <path
            fill="#FFF"
            fillRule="evenodd"
            d="M25.311 11.862h2.807l-6.163 6.907 7.2 9.369h-5.65l-4.423-5.694-5.065 5.694h-2.806l6.528-7.387-6.894-8.889h5.79l3.996 5.201 4.68-5.201zm-.982 14.654h1.556L15.817 13.423h-1.671l10.183 13.093z"
        />
    </svg>
)
export default XIcon
