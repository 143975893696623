// eslint-disable-next-line 

import React from 'react'
import LayoutHome from '../../../components/layout-home/LayoutHome';
import BannerContent from './BannerContent';
import styles from '../styles/Home.module.scss'

export default function Banner() {
  return (
    <div className="bg-[#f3f9ff] ">
      <LayoutHome
        className={`flex flex-col justify-center items-center `}
        title={"스타즈 홀덤 게임에 대해 살펴볼까요?"}
        titleStyle={styles.banner_header}
      >
        <BannerContent />
      </LayoutHome>
    </div>
  );
}
