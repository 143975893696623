// eslint-disable-next-line

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LogoMobile from "../../assets/logo_2024-07-02/logo@3x.png";
import Logo from "../../assets/logo.png";
import IconMenu from "../../assets/menu_2024-06-28/menu.png";
import navArray, { navArrayMobile } from "../../data/nav";
import LogoIconAside from "../../assets/logo_h_2024-07-03/logo_h@3x.png";
import { Link as ScrollLink } from "react-scroll";
import "../header/Header.scss";
import IconClose from "../../assets/x_2024-07-03/x.png";
import { Button, Drawer } from 'antd';

const FIXED_HEIGHT = 80;

const Header: React.FC = () => {
    const location = useLocation();

    const [isMenuShow, setIsMenuShow] = useState(false);
    const [navbar, setNavBar] = useState(false);
    const [open, setOpen] = useState(false);

    const onToggleMenu = () => {
        setIsMenuShow(!isMenuShow);
        setOpen(false);
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };


    window.addEventListener("scroll", () => {
        if (window.scrollY > FIXED_HEIGHT) {
            setNavBar(true);
        } else {
            setNavBar(false);
        }
    });

    useEffect(() => {
        if (isMenuShow) {
            document.body.style.overflow = "";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isMenuShow]);

    return (
        <>
            <header id="nav_1" className={`absolute top-0 left-0 right-0 z-[20] `}>
                <div className={`navbar_header  ${navbar ? "menu-sticky" : ""}  flex justify-between items-center text-center p-[10px] `}>
                    <div className="logo-img">
                        <ScrollLink to="nav_1">
                            <img src={Logo} alt="logo" />
                        </ScrollLink>
                    </div>

                    <button
                        className="transition-transform duration-300 ease-in-out"
                        // onClick={handleClickMenu}
                        onClick={showDrawer}
                    >
                        <div className="menu-icon">
                            <img src={IconMenu} alt="logo" />
                        </div>
                    </button>
                </div>
                <div className={`menu ${navbar ? "menu-sticky" : ""}`}>
                    <div className="nav_bar_1  container mx-auto">
                        <div className=" flex  items-center text-center pt-[10px]">
                            <div className={`logo   xl:pl-[100px] 2xl:pl-[240px]`}>
                                <ScrollLink to="section_1">
                                    <img src={Logo} alt="logo" />
                                </ScrollLink>
                            </div>
                            <div
                                className={`hidden lg:flex  w-full justify-center text-center items-center  xl:mr-[250px] nav`}
                            >
                                {navArray.map((item) => (
                                    <ScrollLink
                                        to={item.to}
                                        key={item?.title}
                                        spy={true}
                                        activeClass={
                                            item.to !== "section_1" ? "navbar-item-text-active" : ""
                                        }
                                        className={`navbar navbar-item-text 
                                        ${
                                            //todo: check condition when all page complete
                                            location.pathname === item.href &&
                                                location.pathname !== "/"
                                                ? "navbar-item-text-active"
                                                : ""
                                            }`}
                                        onClick={() => { }}
                                    >
                                        {item?.title}
                                    </ScrollLink>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* //mobile  */}
            {/* <aside className={isMenuShow ? "aside" : "aside-hidden aside"}>
                <div className="aside-container">
                    <div className="backdrop" onClick={onToggleMenu}></div>
                    <div className="aside-content">
                        <ScrollLink to="section_1">
                            <div onClick={onToggleMenu} className="logo-mobile">
                                <img src={LogoIconAside} alt="logo" />
                            </div>
                        </ScrollLink>
                        <div className="aside-content-menu">
                            {navArrayMobile.map((item, index) => (
                                <div className="aside-content-item">
                                    <ScrollLink
                                        offset={-50}
                                        onClick={onToggleMenu}
                                        key={index}
                                        to={item.to}
                                    >
                                        <span>{item.title}</span>
                                    </ScrollLink>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </aside> */}
            <Drawer width={250} closable={false} onClose={onClose} open={open} className="bg-[#0c257b]">
                <div className="flex flex-row">
                    <ScrollLink to="nav_1">
                        <div onClick={onToggleMenu} className="logo-mobile">
                            <img src={LogoIconAside} alt="logo" />
                        </div>
                    </ScrollLink>
                    <div className="w-[40%] h-[100%] mt-[22px]" onClick={onToggleMenu}>
                        <img src={IconClose} alt="icon close" />
                    </div>
                </div>
                <div className="aside-content-menu">
                    {navArrayMobile.map((item, index) => (
                        <div className="aside-content-item">
                            <ScrollLink
                                offset={-50}
                                onClick={onToggleMenu}
                                key={index}
                                to={item.to}
                            >
                                <span>{item.title}</span>
                            </ScrollLink>
                        </div>
                    ))}
                </div>
            </Drawer>
        </>
    );
};

export default Header;
