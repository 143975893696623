// eslint-disable-next-line 
import logo1 from "../../assets/newIcon1.png";
import logo2 from "../../assets/newIcon4.png";
import logo3 from "../../assets/newIcon3.png";

const dataBanner = [
    {
        id: 1,
        title: '캐쉬게임',
        description: '지금 바로 다른 플레이어와의',
        subTitle: '대결을 시작해보세요.',
        buttonText: 'CashGames',
        imageUrl: logo1,
        subContent: [
            {
                id: 1,
                subTitle: '플레이어의 캐시로 다른 플레이어와 대결하게 됩니다.',
            },
            {
                id: 2,
                subTitle: '바이인 buy-in : 테이블 마다 정해진 범위 안에 금액을 내고 입장할 수 있습니다.',
            }
        ]
    },
    {
        id: 2,
        title: '토너먼트',
        description: '정해진 시간 안에 최후의 1인이',
        subTitle: '될 때까지 살아남으세요.',
        buttonText: 'Tournaments',
        imageUrl: logo2,
        subContent: [
            {
                id: 2,
                subTitle: '일정한 금액을 바이인으로 지불하고 마지막 한 명의 플레이어가 남을 때까지 대결하는 방식입니다.',
            },
            {
                id: 3,
                subTitle: '배팅을 유도하기 위한 강제 베팅으로 라운드가 진행될 때마다 블라인드가 올라갑니다.',
            }
        ]
    },
    {
        id: 3,
        title: '싯 앤 고',
        description: '자리에 앉으면 즉시 시작되는',
        subTitle: '토너먼트를 시작해보세요',
        buttonText: 'Sit & Go',
        imageUrl: logo3,
        subContent: [
            {
                id: 1,
                subTitle: '정해진 플레이어 수가 자리에 앉으면 게임이 시작됩니다.',
            },
            {
                id: 2,
                subTitle: '일정한 금액을 바이인으로 지불하고 마지막 한 명의 플레이어가 남을 때까지 대결합니다.',
            },
            {
                id: 3,
                subTitle: '배팅을 유도하기 위한 강제 베팅으로 라운드가 진행될 때마다 블라인드가 올라갑니다.',
            }
        ]
    },
]

export default dataBanner;


