// eslint-disable-next-line 

import UpdownIcon from "../../../icons/UpdownIcon";
import React from "react";
import Styles from "../styles/Home.module.scss";
import DownIcon from "../../../assets/down_icon_2024-06-18/down_icon.png";
import bannerImg from "../../../assets/01_main_bg_2024-06-28/01_main_bg.png";
import { Link as ScrollLink } from "react-scroll";
type Props = {
    [key: string]: any
}
const ContentSection: React.FC = (props: Props) => {

    return (
      <div>
        <div className={Styles.background_image}>
          <div className={Styles.overlay_1}>
            <img src={bannerImg} alt="" />
          </div>
          <img src={bannerImg} alt="" />
        </div>
        <div className={Styles.content_div_3}>
          <div
            id="section_1"
            className={`${Styles.content_div_2} flex flex-col justify-center items-start lg:items-start w-full  text-center  xl:pt-[250px]`}
          >
            <span className={Styles.title}>빠르고 안전한</span>
            <span className={Styles.title}>홀덤 솔루션 플랫폼 서비스</span>
            <span className={`${Styles.title1} hidden lg:block`}>
              스타즈 홀덤 서비스는 에이전트사의 손해가 발생하지 않고 수익을
              창출하는 솔루션입니다.
            </span>

            <div
              className={`${Styles.content_div_1} flex flex-col  justify-center items-center  lg:hidden`}
            >
              <span className={Styles.title1}>스타즈 홀덤 서비스는</span>
              <span className={Styles.title1}>에이전트사의 손해가</span>
              <span className={Styles.title1}>발생하지 않고 수익을</span>
              <span className={Styles.title1}>창출하는 솔루션입니다.</span>
            </div>
          </div>
          <div className="hidden lg:block">
            <ScrollLink to={'section_2'}>
              <div
                className={`${Styles.content_div_4_title} flex flex-col justify-center  items-center w-full     xl:w-[80%] xl:mt-[-8px]`}
              >
                <span className={Styles.title2}>자세히보기</span>
                <img
                  src={DownIcon}
                  className={`${Styles.img_icon_dow} text-center flex flex-col justify-center items-center mt-[20px] object-contain w-[26px] h-[58px]`}
                ></img>
              </div>
            </ScrollLink>
          </div>
        </div>
      </div>
    );
}

export default ContentSection;