// eslint-disable-next-line 

import { useCallback } from "react";
import dataBanner from "../../../data/banner";
import Styles from '../styles/Home.module.scss';
import styles from "../styles/Banner.module.scss";
import DotIcon from "../../../icons/DotIcon";
const BannerContent = () => {

    const colorMap = {
        1: Styles.banner_1,
        2: Styles.banner_2,
        3: Styles.banner_3,
    };

    const changeColor = useCallback((buttonText: number) => {
        return colorMap[buttonText as keyof typeof colorMap]
    }, [colorMap]);

    const renderBanner = useCallback(() => {
        return dataBanner.map((banner) => (
            <div key={banner?.id} className={`${styles.bg}`}>
                <div
                    className={`${banner?.id === 2 ? `flex  flex-col-reverse lg:flex-row ${styles.banner_2}` : ""} ${styles.banner_1}`}
                >
                    {banner?.id === 2 &&
                        <img
                            className={`${styles.banner_img} ${styles.banner_img2} pt-[20px] lg:pt-0`}
                            src={banner.imageUrl} alt={banner.title} />
                    }
                    <div className={banner?.id === 2 ? styles.banner_content_1 : ``}>
                        {
                            banner?.id !== 2 ? <div className={styles.text_box}>
                                <div className={`${changeColor(banner.id)} ${banner?.id === 2 ? styles.text_box_02 : styles.text_box_01}`}>
                                    <span className={`${banner?.id === 2 ? styles.tour : styles.CashGames}`}>{banner.buttonText}</span>
                                </div>
                                <span className={`${banner?.id === 2 ? styles.title_tour : styles.banner_title}`}>
                                    {banner.title}
                                </span>
                                <div className={`${banner?.id === 2 ? styles.tour_des : styles.banner_description}`}>
                                    <span>{banner.description}</span>
                                    <span>{banner.subTitle}</span>
                                </div>
                            </div> :
                                <div className={styles.text_box_1}>
                                    <div className={styles.button_tour}>
                                        <div className={`${Styles.banner_2} ${styles.text_box_02}`}>
                                            <span className={`${styles.tour}`}>{banner.buttonText}</span>
                                        </div>
                                    </div>
                                    <span className={`${styles.title_tour}`}>
                                        {banner.title}
                                    </span>
                                    <div className={styles.tour_content}>
                                        <div className={`${styles.tour_des}`}>
                                            <span>{banner.description}</span>
                                            <span>{banner.subTitle}</span>
                                        </div>
                                    </div>
                                </div>
                        }
                        {banner?.id === 2 &&
                            <div className={`${styles.banner_img3} ${styles.banner_img_res_2}`}>
                                <div className={styles.overlay}>
                                    <img
                                        className={`  pt-[20px] lg:pt-0`}
                                        src={banner.imageUrl} alt={banner.title} />
                                </div>
                            </div>
                        }
                        {banner?.id !== 2 && <div className={`${styles.banner_img} ${styles.banner_img_res_2}`}>
                            <div className={styles.overlay}>
                                <img src={banner.imageUrl} alt={banner.title} />
                            </div>
                        </div>}
                        {banner.subContent?.map((subContent, index) => (
                            <div
                                key={subContent?.subTitle}
                                className={`flex items-center ${banner?.id === 2 ? 'pl-[10px] md:pl-[28px]  lg:pl-[236px]' : styles.sub_box} ${styles.responsive_content}`}>

                                <span className={`flex ${banner?.id === 2 ? styles.tour_sub : styles.buy_in}`}>
                                    <div
                                        className="mt-[8px] xl:mt-[10px] mx-[5px]"
                                    >
                                        <DotIcon />
                                    </div>
                                    {subContent.subTitle}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                {banner?.id !== 2 && <img className={`${styles.banner_img} ${styles.banner_img_res_1}`} src={banner.imageUrl} alt={banner.title} />}
            </div>
        ));
    }, [changeColor]);

    return <div className={styles.container_banner_scale}>{renderBanner()}</div>;
}

export default BannerContent;