import * as React from "react"
const DotIcon = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={8} height={8} {...props}>
        <path
            fill="#CCC"
            fillRule="evenodd"
            d="M3.1 0c3.109 0 4 1.791 4 4s-.891 3.1-4 3.1C1.791 7.1 0 6.209 0 4s1.791-4 3.1-4z"
        />
    </svg>
)
export default DotIcon
