// eslint-disable-next-line 

import React from "react";

interface LayoutHomeProps {
    title: string;
    children: React.ReactNode;
    className?: string,
    titleStyle?: string
}
const LayoutHome: React.FC<LayoutHomeProps> = (options) => {

    const {
        children,
        title,
        className,
        titleStyle
    } = options || {};

    return (
        <div className='container mx-auto'>
            <div className={className}>
                <span className={titleStyle}>{title}</span>
                {children}
            </div>
        </div>
    )
}

export default LayoutHome;