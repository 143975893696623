import React from 'react'
import { useRoutes } from "react-router"
import MainLayout from '../layout/main-layout/MainLayout'
import Home from '../containers/home'
import GamePage from '../containers/Game'
import Test from '../containers/home/test'


const Routers = () => {
  const routing = useRoutes([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/game",
          element: <GamePage />,
        },
        {
          path: "/test",
          element: <Test />,
        },
      ],
    },
  ]);
  return routing
}

export default Routers