import * as React from "react"
const IconFb = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
        <path
            fill="#222"
            fillRule="evenodd"
            d="M19.1 0C31.045 0 40 8.954 40 19.1c0 11.946-8.955 20-20.9 20C8.954 39.1 0 31.046 0 19.1 0 8.954 8.954 0 19.1 0z"
        />
        <path
            fill="#FFF"
            fillRule="evenodd"
            d="M21.8 29.273v-8.462h2.736l.41-3.299H21.8v-2.105c0-.956.255-1.606 1.574-1.606l1.683-.001v-2.949a21.398 21.398 0 0 0-2.452-.131c-2.425 0-4.087 1.537-4.087 4.36v2.432h-2.745v3.299h2.745v8.462H21.8z"
        />
    </svg>
)
export default IconFb
