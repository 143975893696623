import * as React from "react"
const IconInstagram = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
        <path
            fill="#222"
            fillRule="evenodd"
            d="M20 0c11.045 0 19.1 8.954 19.1 19.1 0 11.946-8.055 20-19.1 20-11.046 0-20-8.054-20-20C0 8.954 8.954 0 20 0z"
        />
        <path
            fill="#FFF"
            fillRule="evenodd"
            d="M23.879 29.163H16.12a5.29 5.29 0 0 1-5.296-5.288v-7.747a5.293 5.293 0 0 1 5.296-5.29h7.759a5.293 5.293 0 0 1 5.296 5.29v7.747a5.29 5.29 0 0 1-5.296 5.288zm3.606-13.08a3.515 3.515 0 0 0-3.517-3.512h-7.94a3.516 3.516 0 0 0-3.516 3.512v7.928a3.516 3.516 0 0 0 3.516 3.513h7.94a3.516 3.516 0 0 0 3.517-3.513v-7.928zm-2.565.136a1.11 1.11 0 0 1-1.11-1.109 1.11 1.11 0 0 1 2.219 0c0 .613-.495 1.109-1.109 1.109zm-4.921 8.583a4.757 4.757 0 0 1-4.761-4.754 4.757 4.757 0 0 1 4.761-4.756 4.76 4.76 0 0 1 4.763 4.756 4.761 4.761 0 0 1-4.763 4.754zm0-7.838a3.084 3.084 0 1 0 0 6.165 3.083 3.083 0 1 0 0-6.165z"
        />
    </svg>
)
export default IconInstagram
