import React from 'react';
import Header from '../../components/header';
import { Outlet, useLocation } from 'react-router';
import Footer from '../../components/footer';
const MainLayout: React.FC = () => {
      const location = useLocation();
    return (
      <>
        <Header />
        <Outlet />
        {location.pathname !== "/game" && <Footer />}
      </>
    );
}

export default MainLayout;