
import DotIcon from "../../../icons/DotIcon";
import game_01 from "../../../assets/game-01-graphic@3x.png";
import game_02 from "../../../assets/game-02-graphic@3x.png";
import game_03 from "../../../assets/game-03-graphic@3x.png";
import "../styles/content.scss";

const BannerContent1 = () => {
    return (
        <div className="banner-content-clone-1">
            <h1 className="game-page-title" style={{}}>
                스타즈 홀덤 게임에 대해 살펴볼까요?
            </h1>
            <div className="games-container">
                {games.map((game, index) => (
                    <GameCard key={index} game={game} index={index} />
                ))}
            </div>
        </div>
    );
};

const GameCard = ({ game, index }: any) => {
    return (
        <div className={`game-card ${index % 2 !== 0 ? "game-card-reverse" : ""}`}>
            <div className="game-card-content">
                <div style={{ background: game.color }} className="game-card-type">
                    {game.type}
                </div>
                <p className="game-card-title" style={{}}>
                    {game.title}
                </p>
                <p className="game-card-description">{game.description}</p>
                <div className="game-card-details">
                    {game.details.map((item: string, index: number) => {
                        return (
                            <div className="game-card-detail" key={index}>
                                <div className="mt-[8px] xl:mt-[10px] mx-[5px]">
                                    <DotIcon />
                                </div>
                                <p>{item}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="game-card-img">
                <img src={game.img} alt="" />
            </div>
        </div>
    );
};

const games = [
    {
        type: "CashGames",
        title: "캐쉬게임",
        description: "지금 바로 다른 플레이어와의 \n대결을 시작해보세요.",
        details: [
            "플레이어의 캐시로 다른 플레이어와 대결하게 됩니다.",
            "바이인 buy-in : 테이블 마다 정해진 범위 안에 금액을  \n 내고 입장할 수 있습니다.",
        ],
        color: "#fcd900",
        img: game_01,
    },
    {
        type: "Tournaments",
        title: "토너먼트",
        description: "정해진 시간 안에 최후의 1인이 \n 될 때까지 살아남으세요.",
        details: [
            "일정한 금액을 바이인으로 지불하고 마지막 한 명의 플레이어가 남을 때까지 대결하는 방식입니다.",
            "배팅을 유도하기 위한 강제 베팅으로 라운드가 진행될 때마다 블라인드가 올라갑니다.",
        ],
        color: "#3ec0f7",
        img: game_02,
    },
    {
        type: "Sit & Go",
        title: "싯 앤 고",
        description: "자리에 앉으면 즉시 시작되는 \n 토너먼트를 시작해보세요.",
        details: [
            "정해진 플레이어 수가 자리에 앉으면 게임이 시작됩니다.",
            "일정한 금액을 바이인으로 지불하고 마지막 한 명의 플레이어가 남을 때까지 대결합니다.",
            "배팅을 유도하기 위한 강제 베팅으로 라운드가 진행될 때마다 블라인드가 올라갑니다.",
        ],
        color: "#d1cdcd",
        img: game_03,
    },

];
export default BannerContent1;
