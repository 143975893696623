// eslint-disable-next-line 

import React, { useCallback } from 'react'
import LayoutHome from '../../../components/layout-home/LayoutHome'
import serviceData from '../../../data/service';
import Styles from '../styles/Home.module.scss'
import "../styles/service.scss"
export default function Service() {


    const renderService = useCallback(() => {
        return serviceData.map((service) => {
            return <div key={service?.id}
                className='box'
            >
                <div className='flex flex-col justify-start items-start p-[20px] lg:p-[35px] '>
                    <p className={`service_sub`}>{service.title}  <p className={`service_description`}>{service.description}</p></p>

                </div>
                <div className='img_contain'>
                    <img src={service.imageUrl} alt={service.title} />
                </div>

            </div>

        })
    }, []);

    return (
        <div id="section_3" className='bg-[#ffffff] '>
            <LayoutHome
                title={"스타즈 홀덤 서비스에 대해 알려드려요."}
                className='flex flex-col justify-center items-center'
                titleStyle="service_title"
            >
                <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-[20px] sm:mb-0 xl:my-[100px] res_service'>
                    {renderService()}
                </div>
            </LayoutHome>
        </div>
    )
}
