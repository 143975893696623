// eslint-disable-next-line 
import Img6 from '../../assets/03_service_img_01_2024-06-25/03_service_img_01.jpg';
import Img2 from '../../assets/img_service_04.png';
import Img3 from '../../assets/img_service_05.png';
import Img4 from '../../assets/img_service_06.png';
import Img1 from '../../assets/img_service_08.png';
import Img5 from '../../assets/img_service_6.png';

const serviceData = [
    {
        id: 1,
        title: '24시간 서비스',
        description: '연중 무휴 서비스 데스크',
        imageUrl: Img6
    },
    {
        id: 2,
        title: '24시간 입출금',
        description: '에이전트 운영에 최적화',
        imageUrl: Img2
    },
    {
        id: 3,
        title: '최고의 홀덤 솔루션 지원',
        description: '홀덤 게임에 최적화',
        imageUrl: Img3
    },
    {
        id: 4,
        title: '타사 대비 낮은 요율',
        description: '에이전트의 수익 최대화',
        imageUrl: Img4
    },
    {
        id: 5,
        title: '멀티 게임 가능',
        description: '플레이어 참여율 증가 및 에이전트 수익 증가',
        imageUrl: Img5
    },
    {
        id: 6,
        title: '개발지원',
        description: '개발에 필요한 모든 지원',
        imageUrl: Img1
    }
]

export default serviceData