import React from 'react'
import "../styles/Home.module.scss"
import background from "../../../assets/01_main_bg_2024-06-28/01_main_bg.png"
import "../test/test.scss"
export default function Test() {
    return (
        <main>
            <section className="hero">
                <div className="content">
                    <h1>빠르고 안전한 홀덤 솔루션 플랫폼 서비스</h1>
                    <p>스타즈 홀덤 서비스는 에이전트사의 손해가 발생하지 않고 수익을 창출하는 솔루션입니다.</p>
                </div>
                <div className="illustration">
                    <div className='overlay'>
                        <img src={background} alt="Platform Illustration" />
                    </div>
                </div>
                <p className="cta">스타즈 홀덤 게임에 대해 살펴볼까요?</p>
            </section>
        </main>


    )
}
